/**
 * Exit intent
 * @version 0.1.0
 * @author Jaroslav Slaby <jaroslav.slaby@gendigital.com>
 * @copyright Gen &copy;2023
 */

(function() {
    'use strict';

    //if (!/exitIntent=true/.test(document.cookie)) {

        var mouseEvent = function(e) {
            if (!e.toElement && !e.relatedTarget && e.clientY < 10) {
                document.removeEventListener('mouseout', mouseEvent);

                //document.cookie = 'exitIntent=true; path=/; max-age=2592000;';

                window.dataLayer.push({
                    'event': 'av.genericEvent',
                    'eventCategory': 'Exit Intent',
                    'eventAction': 'Exit Intent Detected - DEV'
                });
            }
        };

        setTimeout(function() {
            document.addEventListener('mouseout', mouseEvent)
        }, 10000);
    //}

})();
